import * as React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Link } from 'gatsby'
import { SuitHeartFill } from 'react-bootstrap-icons'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
            <div className="d-none d-sm-block pb-4">
              <Link to="/impressum/">Impressum</Link>
              {' | '}
              <Link to="/datenschutz/">Datenschutzerklärung</Link>
              {' | '}
              <a href="/Widerrufsbelehrung.pdf" rel="noreferrer" target="_blank">
                Widerrufsbelehrung
              </a>
            </div>
            <div className="d-sm-none pb-3">
              <ul className="list-unstyled">
                <li>
                  <Link to="/impressum/">Impressum</Link>
                </li>
                <li>
                  <Link to="/datenschutz/">Datenschutzerklärung</Link>
                </li>
                <li>
                  <a href="/Widerrufsbelehrung.pdf" rel="noreferrer" target="_blank">
                    Widerrufsbelehrung
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center pb-5">
          <Col className="text-center">
            <ul className="list-unstyled">
              <li>
                © {new Date().getFullYear()}{' '}
                <Link className="font-handwritten text-primary" to="/">
                  <span className="h6">Kinderbuch</span>
                  <span className="">.party</span>
                </Link>
              </li>
              <li>
                <span className="fst-italic align-middle">
                  Built with <SuitHeartFill className="heart" /> for our children.
                </span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
