import { Link } from 'gatsby'
import React, { useState } from 'react'
import { List } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { default as BSNavbar } from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import balloons from '../assets/img/exp-logo-navbar-balloons-colored.svg'
import logoName from '../assets/img/exp-logo-navbar-name.svg'

function Navbar({ logoVisible }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const hidden = logoVisible ? 'd-none' : ''
  return (
    <BSNavbar className={logoVisible ? 'transparent' : ''} collapseOnSelect variant="light" expand="lg">
      <Container className="" fluid="xxl">
        <Link className={hidden + ' navbar-brand me-2'} to="/">
          <img alt="" src={balloons} height="40" className="balloons" />
        </Link>
        <Link className={hidden + ' navbar-brand'} to="/">
          <img alt="" src={logoName} height="35" className="name" />
        </Link>
        <BSNavbar.Toggle className={hidden + ' toggler'} onClick={handleShow}>
          <List />
        </BSNavbar.Toggle>
        <BSNavbar.Collapse className="justify-content-end d-none d-lg-block">
          <Nav>
            {/* <Link className={'nav-link ' + hidden} to="/#home">
              Startseite
            </Link> */}
            <Link className="nav-link" to="/#me">
              Über mich
            </Link>
            <Link className="nav-link" to="/#party">
              Kinderbuchparty
            </Link>
            <Link className="nav-link" to="/#area">
              Servicegebiet
            </Link>
            <Link className="nav-link" to="/#contact">
              Gastgeber:in werden
            </Link>
          </Nav>
        </BSNavbar.Collapse>
        <Offcanvas
          className=""
          show={show}
          scroll={true}
          onHide={handleClose}
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end">
              {/* <Link className="nav-link" onClick={handleClose} to="/#home">
                Startseite
              </Link> */}
              <Link className="nav-link" onClick={handleClose} to="/#me">
                Über mich
              </Link>
              <Link className="nav-link" onClick={handleClose} to="/#party">
                Bücherparty
              </Link>
              <Link className="nav-link" onClick={handleClose} to="/#contact">
                Gastgeber:in werden
              </Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </BSNavbar>
  )
}

export default Navbar
