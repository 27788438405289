/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import ogImage from '../assets/img/exp-og-image.jpg'
import banner1x1 from '../assets/img/exp-1x1_banner.jpg'
import banner4x3 from '../assets/img/exp-4x3_banner.jpg'
import banner16x9 from '../assets/img/exp-16x9_banner.jpg'

const Seo = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            orgName
            author {
              name
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${ogImage}`,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: `de_DE`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BookStore',
          url: site.siteMetadata.siteUrl,
          name: site.siteMetadata.orgName,
          image: [
            `${site.siteMetadata.siteUrl}${banner1x1}`,
            `${site.siteMetadata.siteUrl}${banner4x3}`,
            `${site.siteMetadata.siteUrl}${banner16x9}`,
          ],
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Paunzhausen',
            addressRegion: 'Bavaria',
            postalCode: '85307',
            addressCountry: 'DE',
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 48.474459,
            longitude: 11.567701,
          },
        })}
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
